import PropTypes from 'prop-types'
import React from 'react'
import * as ReactRedux from 'react-redux'

const ReactReduxContext = React.createContext()

const DEFAULT_CONNECT_OPTIONS = {context: ReactReduxContext}

export function connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
  extraOptions
) {
  const options = extraOptions
    ? Object.assign({}, DEFAULT_CONNECT_OPTIONS, extraOptions)
    : DEFAULT_CONNECT_OPTIONS
  return ReactRedux.connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps,
    options
  )
}

export function useDispatch() {
  const {store} = React.useContext(ReactReduxContext)
  return store.dispatch
}

export function useSelector(selector) {
  const {store} = React.useContext(ReactReduxContext)
  return selector(store.getState())
}

export function Provider(props) {
  return (
    <ReactRedux.Provider context={ReactReduxContext} store={props.store}>
      {props.children}
    </ReactRedux.Provider>
  )
}

Provider.propTypes = {
  children: PropTypes.node,
  store: PropTypes.shape({
    dispatch: PropTypes.func,
    getState: PropTypes.func,
  }),
}
