import * as R from 'ramda'
import * as actions from './actions'
import * as selectors from './selectors'
import * as types from './types'

function getRealityCheckData(token) {
  const data = window.sessionStorage.getItem('realityCheck')

  if (data != null) {
    const [prevToken, remainder] = R.split(':', data)

    if (prevToken === token) {
      return {
        remainder: remainder && parseInt(remainder),
      }
    }
  }

  return {}
}

function setRealityCheckData(data) {
  window.sessionStorage.setItem(
    'realityCheck',
    R.join(':', [data.token, data.remainder])
  )
}

export function realityCheckMiddleware(store) {
  if (!process.browser) {
    return next => action => next(action)
  }

  window.setTimeout(() => {
    store.dispatch(actions.resetRealityCheck())
  }, 1)

  window.setInterval(() => {
    const state = store.getState()
    const nextTriggerTime = selectors.getNextRealityCheckTime(state)
    const resetTriggerType = selectors.getRealityCheckResetTrigger(state)

    if (nextTriggerTime) {
      const currentTime = Date.now()
      const remainder = Math.max(0, nextTriggerTime - currentTime)
      const token = selectors.getToken(state)

      if (resetTriggerType === 'session-end') {
        setRealityCheckData({
          remainder,
          token,
        })
      }

      if (remainder <= 0) {
        setRealityCheckData({
          remainder: null,
          token,
        })
        store.dispatch(actions.triggerRealityCheck())
      }
    }
  }, 10000)

  return next => action => {
    if (action.type === types.REALITY_CHECK_RESET) {
      const state = store.getState()
      const period = selectors.getRealityCheckPeriod(state)
      const {remainder} = getRealityCheckData(selectors.getToken(state))
      if (period) {
        const currentTime = Date.now()
        const nextRealityCheckTime = remainder
          ? currentTime + remainder
          : currentTime + period

        return next(R.assoc('payload', nextRealityCheckTime, action))
      }
    }

    return next(action)
  }
}
