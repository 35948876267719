import {loadableReady} from '@loadable/component'
import React from 'react'
import {hydrate} from 'react-dom'

import * as data from './data'
import {App} from './app'

const store = data.createStore({
  initialState: window.__APP_DATA__,
})

delete window.__APP_DATA__

loadableReady(() => {
  hydrate(
    <data.Provider store={store}>
      <App location={window.location.pathname + window.location.search} />
    </data.Provider>,
    document.getElementById('root')
  )
})

if (module.hot) {
  module.hot.accept()
}
