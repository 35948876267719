import * as R from 'ramda'
import { useSelector } from './provider'

export function getLaunchMethod(state) {
  if (isBlitz(state)) {
    return 'iframe'
  }

  return R.path(['game', 'launchMethod'], state)
}

export function getLaunchOptions(state) {
  if (isBlitz(state)) {
    return {}
  }

  return R.path(['game', 'launchOptions'], state)
}

export function useGame() {
  return useSelector((state) => R.pathOr({},['game'], state))
}

export function useLaunchOptions() {
  return useSelector(getLaunchOptions)
}

export function getLanguage(state) {
  return R.toLower(R.pathOr('', ['language'], state))
}

export function getBetbyConfig(state) {
  return R.path(['betbyConfig'], state)
}

export function getBetbyNextRoute(state) {
  return R.path(['betbyNextRoute'], state)
}

export function getLaunchUrl(state) {
  if (isBlitz(state)) {
    return R.path(['game', 'blitzProperties', 'launchUrl'], state)
  }

  return R.path(['game', 'launchUrl'], state)
}

export function getLicenseDisclaimer(state) {
  return R.path(['licenseDisclaimer'], getLaunchOptions(state))
}

export function getNextRealityCheckTime(state) {
  return R.path(['nextRealityCheckTime'], state)
}

export function getRealityCheckPeriod(state) {
  return R.path(['realityCheckPeriod'], state)
}

export function getRealityCheckResetTrigger(state) {
  return R.path(['realityCheckResetTrigger'], state)
}

export function getToken(state) {
  return R.path(['token'], state)
}

export function isBlitz(state) {
  return R.pathEq(['mode'], 'blitz', state)
}

export function isPaused(state) {
  return Boolean(R.pathOr(false, ['paused'], state))
}

export function isRealityCheck(state) {
  return R.pathEq(['paused'], 'rc', state)
}
