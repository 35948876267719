import * as R from 'ramda'

import * as selectors from './selectors'
import * as types from './types'

const INITIAL_STATE = {
  game: {},
  mode: 'classic',
  nextRealityCheckTime: null,
  paused: false,
  realityCheckPeriod: null,
  realityCheckResetTrigger: null,
  token: null,
  language: '',
  betbyConfig: {
    brandId: '',
    themeName: '',
    scriptUrl: '',
  },
}

export function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.INITIALIZED: {
      return R.merge(state, action.payload)
    }

    case types.GAMEPLAY_PAUSED: {
      if (selectors.isRealityCheck(state)) {
        return state
      }

      return R.assoc('paused', true, state)
    }

    case types.GAMEPLAY_RESUMED: {
      if (selectors.isRealityCheck(state)) {
        return state
      }

      return R.assoc('paused', false, state)
    }

    case types.REALITY_CHECK_RESET: {
      return R.merge(state, {
        nextRealityCheckTime: action.payload,
        paused: false,
      })
    }

    case types.REALITY_CHECK_TRIGGERED: {
      return R.merge(state, {
        nextRealityCheckTime: null,
        paused: 'rc',
      })
    }

    case types.BETBY_CONFIG_UPDATED: {    
      return R.mergeDeepRight(state, {
        betbyConfig: action.payload,
      })
    }

    case types.BETBY_ROUTE_UPDATED: {    
      return R.merge(state, {
        betbyNextRoute: action.payload,
      })
    }

    default: {
      return state
    }
  }
}
