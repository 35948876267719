import * as redux from 'redux'
import {composeWithDevTools} from 'redux-devtools-extension/developmentOnly'
import {messagingMiddleware} from './messaging-middleware'
import {realityCheckMiddleware} from './reality-check-middleware'
import {reducer} from './reducer'

export function createStore({initialState}) {
  return redux.createStore(
    reducer,
    initialState,
    composeWithDevTools(
      redux.applyMiddleware(messagingMiddleware, realityCheckMiddleware)
    )
  )
}
