import * as R from 'ramda'
import * as types from './types'

/**
 * Set up action exchange with host application.
 *
 * Middleware subscribes to incoming post messages and dispatches ones with
 * matching types.
 *
 * Also it intercepts specific actions to proxy them to host application.
 */
export function messagingMiddleware(store) {
  if (!process.browser) {
    return next => action => next(action)
  }

  /**
   * Action types to be dispatched when received via `postMessage`
   */
  const ALLOWED_INCOMING = [
    types.BETBY_CONFIG_UPDATED,
    types.BETBY_ROUTE_UPDATED,
    types.GAMEPLAY_PAUSED,
    types.GAMEPLAY_RESUMED,
    types.REALITY_CHECK_RESET,
  ]

  /**
   * Action types to be sent via `postMessage` when dispatched
   */
  const ALLOWED_OUTGOING = [
    types.BALANCE_UPDATED,
    types.BETBY_INITIATED,
    types.BETBY_CONFIG_UPDATED,
    types.BETBY_BANNER_CLICKED,
    types.BETBY_BETSLIP_TOGGLED,
    types.BETBY_LOGIN_BUTTON_CLICKED,
    types.BETBY_ROUTE_CHANGED,
    types.BETBY_REGISTRATION_BUTTON_CLICKED,
    types.BETBY_RECHARGED,
    types.BETBY_SESSION_REFRESHED,
    types.BETBY_SPORTS_CLICKED,
    types.BETBY_EVENT_LINK_CLICKED,
    types.BET_HISTORY_OPENED,
    types.CLASSIC_MODE_OPENED,
    types.GAME_CLOSED,
    types.LIMITS_OPENED,
    types.LOGIN_OPENED,
    types.REALITY_CHECK_TRIGGERED,
    types.WALLET_OPENED,
  ]

  window.addEventListener('message', (event) => {
    const action = R.pathOr({}, ['data'], event)
    if (R.contains(action.type, ALLOWED_INCOMING)) {
      switch (action.type) {
        case types.BETBY_CONFIG_UPDATED: {
          store.dispatch({ type: types.BETBY_CONFIG_UPDATED, payload: action.payload })
          break
        }

        case types.BETBY_ROUTE_UPDATED: {
          store.dispatch({ type: types.BETBY_ROUTE_UPDATED, payload: action.payload.route })
          break
        }

        default: {
          store.dispatch(action)
          break
        }
      }
    }
  })

  return next => action => {
    if (R.contains(action.type, ALLOWED_OUTGOING)) {
      window.top.postMessage(action, '*')
    }

    // pass action to next middleware
    next(action)
  }
}
