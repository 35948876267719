export const BALANCE_UPDATED = '@rushplay/gamer/BALANCE_UPDATED'
export const BET_HISTORY_OPENED = '@rushplay/gamer/BET_HISTORY_OPENED'
export const BETBY_BANNER_CLICKED = '@rushplay/gamer/betby/BANNER_CLICKED'
export const BETBY_BETSLIP_TOGGLED = '@rushplay/gamer/betby/BETSLIP_TOGGLED'
export const BETBY_CONFIG_UPDATED = '@rushplay/gamer/betby/CONFIG_UPDATED'
export const BETBY_EVENT_LINK_CLICKED = '@rushplay/gamer/betby/EVENT_LINK_CLICKED'
export const BETBY_INITIATED = '@rushplay/gamer/betby/INITIATED'
export const BETBY_LOGIN_BUTTON_CLICKED = '@rushplay/gamer/betby/LOGIN_BUTTON_CLICKED'
export const BETBY_REGISTRATION_BUTTON_CLICKED = '@rushplay/gamer/betby/REGISTRATION_BUTTON_CLICKED'
export const BETBY_RECHARGED = '@rushplay/gamer/betby/RECHARGED'
export const BETBY_ROUTE_CHANGED = '@rushplay/gamer/betby/ROUTE_CHANGED'
export const BETBY_ROUTE_UPDATED = '@rushplay/gamer/betby/ROUTE_UPDATED'
export const BETBY_SESSION_REFRESHED = '@rushplay/gamer/betby/SESSION_REFRESHED'
export const BETBY_SPORTS_CLICKED = '@rushplay/gamer/betby/SPORTS_CLICKED'
export const CLASSIC_MODE_OPENED = '@rushplay/gamer/CLASSIC_MODE_OPENED'
export const COOKIE_UPDATED = '@rushplay/gamer/COOKIE_UPDATED'
export const GAME_CLOSED = '@rushplay/gamer/GAME_CLOSED'
export const GAMEPLAY_PAUSED = '@rushplay/gamer/GAMEPLAY_PAUSED'
export const GAMEPLAY_RESUMED = '@rushplay/gamer/GAMEPLAY_RESUMED'
export const INITIALIZED = '@rushplay/gamer/INITIALIZED'
export const LIMITS_OPENED = '@rushplay/gamer/LIMITS_OPENED'
export const LOGIN_OPENED = '@rushplay/gamer/LOGIN_OPENED'
export const REALITY_CHECK_RESET = '@rushplay/gamer/REALITY_CHECK_RESET'
export const REALITY_CHECK_SCHEDULED = '@rushplay/gamer/REALITY_CHECK_SCHEDULED'
export const REALITY_CHECK_TRIGGERED = '@rushplay/gamer/REALITY_CHECK_TRIGGERED'
export const WALLET_OPENED = '@rushplay/gamer/WALLET_OPENED'
